import React from "react";
import { withTranslation } from "react-i18next";
import Bubble from "../Bubble";
import Flag from "../../components/Flag";
let styles = {};
import(/* webpackMode: "eager" */ `./${process.env.REACT_APP_VENDOR}/Avatar.module.scss`).then(
  importedStyles => {
    styles = importedStyles.default;
  }
);

class Avatar extends React.Component {
  timeouts = [];

  constructor(props) {
    super(props);

    if (localStorage.getItem("avatar-message-shown") === "false") {
      this.state = {
        open: true,
        message: props.t("You can click on me anytime. I am here for you."),
        link: null,
        loaded: false,
        email: false
      };
      localStorage.setItem("avatar-message-shown", "true");
    } else {
      this.state = {
        open: null,
        message: null,
        link: null,
        loaded: false,
        email: false
      };
    }
    if (process.env.REACT_APP_VENDOR === "myq") {
      // this.createTransition(null, null, false, 5000);
      // this.createTransition(props.t("Do you need help?"), "#", true, 5001, true);
    } else {
      this.createTransition(null, null, false, 5000);
      this.createTransition(props.t("Do you need help?"), "#", true, 60000);
    }
  }

  getAvatarSrc = () => {
    switch (process.env.REACT_APP_VENDOR) {
      case "myq":
        return `${process.env.PUBLIC_URL}/img/superheroes_zakladni_zena_koala_01.png`;
      case "ta":
        return `${process.env.PUBLIC_URL}/img/ta_guide_woman_04.png`;
      case "utax":
        return `${process.env.PUBLIC_URL}/img/ta_guide_woman_04.png`;
      case "knm":
        return `${process.env.PUBLIC_URL}/img/kyocera_logo_small.png`;
      default:
        return `${process.env.PUBLIC_URL}/img/superheroes_zakladni_zena_koala_01.png`;
    }
  };

  createTransition = (message, link, open, delay, email) => {
    this.timeouts.push(
      setTimeout(() => {
        this.setState({ open: open, link: link, message: message, email: email });
      }, delay)
    );
  };

  handleClick = event => {
    this.timeouts.map(timeout => clearTimeout(timeout));
    this.setState({
      open: true,
      message: "Do you want to take the tour once again? Follow the link.",
      link: "#"
    });
  };

  handleClose = event => {
    clearTimeout(this.dismissTimeout);
    this.setState({
      open: false,
      message: null,
      link: null
    });
  };

  refresh = () => {
    this.setState({
      message: this.props.t("You can click on me anytime. I am here for you.")
    });
  };

  render() {
    const { message, link, open } = this.state;
    const { t, authActions, user } = this.props;
    const knm = process.env.REACT_APP_VENDOR === "knm";

    return (
      <div>
        <div className={styles.container}>
          {open && (
            <Bubble arrow={!knm} skip={this.handleClose} className={styles.bubble}>
              {/*{email && <h6 style={{ margin: 0 }}>{t("Do you want to know more?")}</h6>}*/}
              <div style={{ marginRight: "20px" }}>{t(message)}</div>
              {link && (
                <div>
                  <a className={styles.link} href="/">
                    {t("Start over")}
                  </a>
                </div>
              )}
              {/* {email && (
                <React.Fragment>
                  <div>
                    <p>Write down your Email for further information</p>
                    <iframe
                      title="EmailFrame"
                      src="https://go.myq-solution.com/l/139201/2019-08-21/2hqyhp"
                      frameBorder="0"
                      onLoad={() => {
                        this.setState({ loaded: true });
                      }}
                      height="125px"
                      style={{
                        marginBottom: "-30px",
                        marginLeft: "10px",
                        display: this.state.loaded ? "block" : "none"
                      }}
                    />
                    {!loaded && <div style={{ textAlign: "center" }}>Loading...</div>}
                  </div>
                </React.Fragment>
              )}*/}
            </Bubble>
          )}

          <div onClick={this.handleClick} className={styles.avatar} id="k42-avatar">
            <img className={styles.img} src={this.getAvatarSrc()} alt="avatar" />
          </div>
          {process.env.REACT_APP_VENDOR === "wietholt" ? (
            ""
          ) : (
            <Flag refresh={this.refresh} {...{ authActions, user }} />
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation()(Avatar);
